.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active{
  background: #088538;
  color: #ffffff;
}

.label-layanan-parent{
  display: inline-block;
  padding: 3px;
}
.label-layanan{
  background: #BBEACD;
  display: inline-block;
  padding: 3px;
  border-radius: 10px;
  font-size: 12px;
}
.total-obat{
  font-size: 20px;
  font-weight: bold;
  text-align: right;
}
.label-obat{
  font-size: 14px;
  text-transform: uppercase;
}
.value-obat{
  font-size: 14px;
  font-weight: bold;
}
.qty-obat{
  background: #b8f9bd;
  text-align: center;
}
.qty-obat:focus{
  background: #f9d993;
}
.deleted-obat{
  background: rgb(252, 42, 5, 0.3);
  padding: 3px;
}
.add-obat .ant-col{
  padding: 5px;
}
.form-pembayaran{
  padding: 5px;
}
.label-col::after{

}
.dibayar, .kembali{
  text-align: right;
}
.trigger{
	color: #000;
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.header {
  padding: 0;
  /*box-shadow: @shadow-2;*/
  display: flex;
  justify-content: space-between;
  height: 72px;
  z-index: 9;
  align-items: center;
  /*background: #4B4B4B;dark*/
  background: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 256px);
  z-index: 29;
}
.header.collapsed{
	width: calc(100% - 80px);
}
.cetak-value-kanan{
  text-align: right;
}
/*
.logo{
  height: 32px;
  background: transparent;
  margin: 16px;
}
*/
.logo-splash{
  text-align: center;
  padding: 80px;
}
.site-layout .site-layout-background {
  background: #fff;
  min-height: calc(100% - 72px);
}
.content {
  padding: 10px 24px;
  min-height: calc(100% - 72px - 72px);

  margin-top: 72px;
  
}
.card{
  margin-top: 10px;
}
.isi{
  color: #03090F;	
}
/*
header
*/

.rightContainer {
	display: flex;
    align-items: center;
}
.container {
  height: 100vh;
  flex: 1;
  width: calc(100% - 256px);
  overflow-y: auto;
  overflow-x: hidden;
  background: #F0F2F6;
}
.footer{
	background: #fff;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  min-height: 20px;
}
.be-dashboard-info{
  display: flex;
  align-items: center;
  justify-content: center;
}
.be-dashboard-round, .be-dashboard-info .ant-avatar{
  display: flex;
  align-items: center;
  justify-content: center;
}
.be-dashboard-info .ant-avatar-string{
  display: flex;
  justify-content: center;
  align-items: center;
}
.content .card{
  background: none;
  border: none;
}
/*
sider
*/
.sider {
  box-shadow: fade(#138AFF, 10%) 0 0 28px 0;
  z-index: 10;
  height: 100vh;
  left: 0;
  /*background: #4B4B4B;dark*/
  background: #ffffff;
  
  

}
.no-uppercase{
  text-transform: none;
}
.sidebar{
  /*background: #4B4B4B;dark*/
  background: #ffffff;
	
}
.sidebar:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background: #bbeacd;
  color: #088538;
}
.sidebar .ant-menu-item-selected > a{
  color: #088538;
}
.sidebar:not(.ant-menu-horizontal) .ant-menu-item-active{
  color: #088538;
}
.sidebar .ant-menu-submenu-selected{
  color: #088538;
}
.brand {
  z-index: 1;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
}
.logo{
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo img{
	/*width: 36px;*/
  width: 80%;
    margin-right: 8px;
}
.logo h1{
	vertical-align: text-bottom;
      font-size: 16px;
      text-transform: uppercase;
      display: inline-block;
      font-weight: 700;
      color: #138AFF;
      white-space: nowrap;
      margin-bottom: 0;
}
.ant-menu-dark, .ant-menu-dark .ant-menu-sub{
  color: #DDE0E2;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub{
  /*background: #4B4B4B;dark*/
  background: #ffffff;
}
.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop{
  /*background: #4B4B4B;dark*/
  background: #ffffff;
}
/*
.menu-atas{
  background: transparent;
  color: #DDE0E2;
}
.menu-atas:hover{
  color: #FDFDFD;
}
*/
.label-dashboard{
  font-size: 14px;
  color: #BDC6D0;
}
.counter-dashboard{
  font-size: 30px;
}

.ant-drawer-body{
  padding: 0;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu{
  /*background: #4B4B4B;dark*/
  background: #ffffff;
  /*color: #DDE0E2dark*/;
  color: #138AFF;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu:hover{
  /*background: #4B4B4B;dark*/
  background: #ffffff;
  color: #FDFDFD;
}
.grafik-perbulan{
  /*background: linear-gradient(87deg,#172b4d,#1a174d) !important;*/
}
.chartjs-render-monitor{
  min-height: 300px;
}

.ant-breadcrumb{
  text-transform: uppercase;
}

.isi .ant-col .konten{
  margin: 10px;
}

.konten ol{
  padding: 15px; 
}

.konten-judul{
  color: #a8acaf;
  text-transform: uppercase;
}

.ant-input{
  text-transform: uppercase;
}
.label-succes{
  clear: both;
  background: green;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  color: #fff;
}
/*
.ant-message-notice-content{
  background: #9fdbfc;
  color: #fff;
}
*/
.ant-table.ant-table-small .ant-table-thead > tr > th{
  text-align: center;
  font-weight: bold;
  background: #CECECE;
}
.detail-berita{
  width: 75%;
}
.notifications {
  width: 38px;
}
.notificationItem {
    transition: all 0.3s;
    padding: 12px 24px;
    cursor: pointer;
}
.notificationItem:hover {
      /*background-color: @hover-color;*/
}
.clearButton {
    text-align: center;
    height: 48px;
    line-height: 48px;
    cursor: pointer;
    /*.background-hover();*/
}
.notifIconButton{


}
.btn-primary{
  background: #088538;
  color: #ffffff;
  border-radius: 3px;
}
.btn-refresh{
  background: #64a7d1;
  color: #FFFFFF;
  margin-right: 5px;
  border-radius: 3px;
}
.btn-refresh:hover, .btn-refresh:focus{
  background: #64a7d1;
  color: #FFFFFF;
}
.btn-edit{
  color: #088538;
}
.btn-delete{
  color: #FF0000;
}
.panel-bed{
  background: #F0F2F6;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
}
.panel-bed-available{
  border-radius: 10px;
  background: rgb(4, 247, 61, 0.2);
  text-align: center;
  padding: 10px;
}
.panel-bed-unavailable{
  border-radius: 10px;
  background: rgb(209, 49, 25, 0.2);
  text-align: center;
  padding: 10px;
}
.btn-bed{
  background: #088538;
  color: #ffffff;
}
.bed-total{
  border-radius: 10px;
  background: #1d65f4;
  color: #FFFFFF;
}
.bed-terisi{
  border-radius: 10px;
  background: #d13119;
  color: #FFFFFF;
}
.bed-kosong{
  border-radius: 10px;
  background: #488e54;
  color: #FFFFFF;
}
.penjualan-cart-image{
  width: 100%;
  height: 200px;
  background-position: 50% 50%;
  border-radius: 5px 5px 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

}
.penjualan-cart-cari .ant-input, .penjualan-cart-barcode .ant-input{
  text-transform: none !important;
}
.penjualan-cart-nama{
  font-size: 14px;
  padding: 5px;
  height: 60px;
  display: flex;
  align-items: center;
  color: #000000;
}
.cart-bound{
  padding: 5px;
}
.panel-cart-item{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
}
.penjualan-cart-price{
  background: rgb(0, 126, 0, 0.5);
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 5px;
  font-weight: bold;
  color: #ffffff;
  text-align: right;
}
.penjualan-cart-discount{
  border-radius: 0px 5px 0px 0px;
  background: rgb(244, 79, 34, 0.5);
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px;
  font-weight: bold;
  color: #ffffff;
  text-align: right;
}
/*
.panel-cart-produk{
  height: 715px;
}
*/
.penjualan-cart-kategori{
  margin-top: 10px;
  /*overflow: auto;*/
  /*background: red; */
  white-space: nowrap;
  box-shadow: 0px 0 4px 0 rgba(0,0,0,0.2);
}
.scrollbar-container{
  height: auto !important;
}
.penjualan-cart-kategori-item{
  border-radius: 5px;
  padding: 5px;
  display: inline-block;
  background: rgb(0, 126, 0, 0.5);
  margin: 5px;
}
.penjualan-cart-kategori .ant-btn:hover, 
.penjualan-cart-kategori .ant-btn:focus,
.penjualan-cart-kategori .ant-btn:click,
.penjualan-cart-kategori-item:hover, .penjualan-cart-kategori-item:focus, .penjualan-cart-kategori-item:click{
  background: rgb(0, 126, 0, 0.5) !important;
}
.penjualan-cart-kategori-item-selected{
  border-radius: 5px;
  padding: 5px;
  display: inline-block;
  background: rgb(0, 126, 0, 1);
  color: #fff;
  margin: 5px;
}
.penjualan-panel-kiri{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
}
.penjualan-panel-kanan{
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.total-cart-panel{
  color: #FFFFFF;
  position: absolute;
  bottom: 0px;
  background: #007e00;
  width: 100%;
}
.penjualan-panel-kanan-konten{
  /*
  padding-left: 5px;
  padding-right: 5px;
  */
}
.penjualan-cart-item{
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
}
.penjualan-cart-item-qty{
  font-weight: bold;
  text-align: right;
  font-size: 18px;
  padding-right: 5px;
}
.penjualan-cart-item-discount{
  font-size: 10px;
  color: #939693;
}
.penjualan-cart-item-sub-total{
  text-align: right;
  font-size: 10px;
  font-weight: bold;
}
.penjualan-cart-item-harga{
  text-align: right;
  font-size: 12px;
  color: #939693;
}
.penjualan-cart-item-panel{
  padding-left: 5px;
}
.penjualan-cart-item-nama{
  font-size: 10px;
  font-weight: bold;
}
.penjualan-cart-item-harga{
  font-size: 10px;
}
/*
.penjualan-panel-kanan-konten .scrollbar-container{
  max-height: 745px;
}
*/
.penjualan-pembayaran .ant-modal-header{
  
}
.penjualan-pembayaran-total{
  text-align: right;
  font-size: 20px;
  font-weight: bold;
}
.ant-radio-button-wrapper{
  margin-right: 5px !important;
  margin-bottom: 3px !important;
}
.panel-daftar-belanja{
  background: #2B6C21;
  color: #FFFFFF;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
/*
.notificationPopover {
  :global {
    .ant-popover-inner-content {
      padding: 0;
    }
    .ant-popover-arrow {
      display: none;
    }
    .ant-list-item-content {
      flex: 0;
      margin-left: 16px;
    }
  }
}
*/
/*
tbody tr:nth-child(odd) {
   background-color: #ccc;
}
*/


@media only screen and (min-width: 1367px) and (max-width: 1920px) {
  .panel-cart-produk{
    height: 700px;
  }
  .penjualan-panel-kanan-konten .scrollbar-container{
    max-height: 700px;
  }
}
/* ----------- macbookpro Non-Retina Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
  .panel-cart-produk{
    height: 600px;
  }
  .penjualan-panel-kanan-konten .scrollbar-container{
    max-height: 600px;
  }
}

/* ----------- macbookpro Retina Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) {
  .panel-cart-produk{
    height: 595px;
  }
  .penjualan-panel-kanan-konten .scrollbar-container{
    max-height: 595px;
  } 
}

/* laptop biasa 1366px*/
@media only screen and (min-width: 1366px) and (max-width: 1366px) {
  .panel-cart-produk{
    height: 403px;
    /*overflow-y: auto;*/
  }
  .penjualan-panel-kanan-konten .scrollbar-container{
    max-height: 425px;
  }

}
@media (max-width: 700px) {
  .header.collapsed{
    width: calc(100%);
  }
  .content {
    padding: 12px;
  }

  .backTop {
    right: 20px;
    bottom: 20px;
  }

  .container {
    height: 100vh;
    flex: 1;
    width: 100%;
  }

  .detail-berita{
    width: 100%;
  }
}